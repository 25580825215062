.container-wrapper > .main-nav {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $main-nav-bg;

  &.collapse:not(.show) { display: none; }

  .nav { flex-direction: column; }

  .nav-item { padding: 0 0.5rem; }
  .nav-item .nav-link {
    padding: 0.365rem 0.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    border-radius: 0.25rem;
    font-weight: 500;
    color: $main-nav-link-color;

    > * { margin-right: 1rem; }
    > *:last-child { margin: 0; }

    i { font-size: 1rem; }

    &.active,
    &:hover,
    &:focus { color: $main-nav-link-active-color; }
    &.active { background-color: $main-nav-link-active-background; }

    &.chatbot {
      .chatbot-avatar {
        width: 1.25rem;
        height: 1.5rem;
        margin-right: 0.75rem;
      }
      .badge.notification {
        top: 8px;
        left: 24px;
      }
    }
  }
  .nav-header {
    margin: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    padding: 0;
    color: $main-nav-header-color;
    opacity: 0.5;
  }

  a { display: block; }

  @include media-breakpoint-up(md) {
    .subnav {
      padding-left: 1.5rem;

      .nav-item { max-width: calc($main-nav-width - 1.5rem); }
    }
  }
}
@media (max-width: 374px) {
  .container-wrapper > .main-nav .nav-item .nav-link span { font-size: 10px; }
}
@include media-breakpoint-down(md) {
  .container-wrapper > .main-nav {
    padding: 0.5rem;
    flex-direction: row;
    flex-wrap: nowrap;
    z-index: 100;
    overflow-x: auto;

    .nav {
      width: 100%;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-around;
    }

    .nav-item { padding: 0; }

    .subnav { display: none; }

    .dropdown-menu {
      width: 100vw;
      height: calc(100vh - $navbar-height - 80px);
      @media (max-width: 374px) {
        height: calc(100vh - $navbar-height - 74px);
      }
      padding: $card-padding-x 0;
      overflow: auto;
      border: none;
      border-radius: 0;
      transform: none !important; // !important for overwrite bootstrap popper generated inline styles
      position: fixed !important; // !important for overwrite bootstrap popper generated inline styles
      top: $navbar-height !important; // !important for overwrite bootstrap popper generated inline styles
      left: 0 !important; // !important for overwrite bootstrap popper generated inline styles

      .dropdown-item {
        padding: 0.5rem 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;

        > * { margin-right: 1rem; }
        > *:last-child { margin: 0; }

        &.chatbot {
          .chatbot-avatar {
            width: 1.25rem;
            height: 1.5rem;
            margin-right: 0.625rem;
          }
          .badge.notification {
            top: 8px;
            left: 31px;
          }
        }
      }
    }

    .nav-item .nav-link {
      position: relative;
      flex-direction: column;
      text-align: center;

      > * { margin: 0 0 0.5rem 0; }
      > *:last-child { margin: 0; }

      .notification {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
      }

      &.chatbot {
        .chatbot-avatar { margin-right: 0; }
        .badge.notification { left: calc(50% + 6px); }
      }
    }

    .btn-group-sm { display: none; }
  }
}

.nav .nav-divider {
  @extend .nav-item;

  margin: 0.5rem 0;
  border-top: solid 1px $nav-divider;
}