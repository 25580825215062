.card-body.filters {
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &.disabled {
    z-index: 1;
    position: relative;
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }

  > div {
    display: flex;
    border-right: $card-border-divider;
  }

  .filter-icon {
    @extend .border-0;

    background: $filter-icon-bg;
    color: $filter-icon-color;

    &:not(.p-3) { width: 40px; }
  }

  .boolFilter {
    input { display: none; }
    input + .badge { background: $gray-300; }
    input:checked + .badge {
      &.active { @extend .bg-success; }
      &.prepared { @extend .bg-info; }
      &.finished { @extend .bg-secondary; }
      &.deleted { @extend .bg-danger; }
    }
  }

  .selection-group {
    max-height: 300px;
    overflow: auto;
  }

  .filter-group {
    max-height: 500px;
    overflow: auto;

    .selection-group { max-height: none; }
  }

  select.form-control {
    white-space: nowrap;
    max-width: 9rem;
    text-overflow: ellipsis;
  }

  [data-submitted-form-spinner] {
    position: absolute;
    margin-left: 50%;
    margin-top: 1em;
  }

  .popover {
    min-width: 300px;
    @include media-breakpoint-down(sm) {
      min-width: 200px;
    }

    .selectize-dropdown, .selectize-dropdown.form-control { position: initial; }
  }

  .col-auto {
    align-items: center;
    position: relative;
  }

  > .col-auto.active { background-color: #f1f0f7; }

  .search::placeholder{ color: $primary; }
}
