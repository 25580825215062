.emotions .emotion {
  display: inline-block;
  transition: scale 180ms;
  transform-origin: bottom;

  &:hover,
  &:focus { scale: 1.11; }

  input {
    + label {
      width: 48px;
      height: 48px;
      position: relative;

      &::before {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: $gray-700;
        border-radius: 50%;
        z-index: 1;
        transition: all 150ms ease-out;
      }
    }

    + label img {
      width: 32px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
    }

    &:checked + label::before {
      width: 48px;
      height: 48px;
    }
  }

  span img {
    width: 32px;
    margin-right: 1rem;
  }
}

.star-rating {
  display: inline-block;

  label {
    transform-origin: bottom center;
    transition: color 180ms, scale 180ms;
  }

  input + label { color: $gray-400; }
  input + label:hover,
  input + label:focus,
  input:checked + label { @extend .text-gold; }

  input + label:hover {
    scale: 1.11;
  }
}

// col-12 col-md-6 col-lg-4 col-xl-3
.diary-detail {
  > div:not(:first-child) {
    margin-top: 1rem;
  }

  .stars {
    color: $gold;
    line-height: 100%;
    letter-spacing: -3px;

    .blank { color: $gray-400; }
    * { display: none; }

    &.stars-1 {
      .star-1 { display: inline; }
      .star-empty-2, .star-empty-3, .star-empty-4, .star-empty-5 { display: inline; }
    }
    &.stars-2 {
      .star-1, .star-2 { display: inline; }
      .star-empty-3, .star-empty-4, .star-empty-5 { display: inline; }
    }
    &.stars-3 {
      .star-1, .star-2, .star-3 { display: inline; }
      .star-empty-4, .star-empty-5 { display: inline; }
    }
    &.stars-4 {
      .star-1, .star-2, .star-3, .star-4 { display: inline; }
      .star-empty-5 { display: inline; }
    }
    &.stars-5 {
      .star-1, .star-2, .star-3, .star-4, .star-5 { display: inline; }
    }
  }
}

@include media-breakpoint-down(md) {
  .diary-module > .row {
    > .col-12 { order: 1; }
    > .col-12.col-lg-8.col-xl-9.mb-3 { order: 3; }
    > .sidebar { order: 2; }
  }
}