.user-profile {
  .user-profile-info .card {
    display: flex;
    flex-direction: column;
    border-top: $card-border-top;

    .card-body { padding: $card-padding-x; }
    .card-body:first-child { background: $primary-100; }

    .avatar {
      width: 4rem;
      height: 4rem;
    }
  }

  .user-profile-attribute {
    display: flex;
    flex-direction: column;
    border-top: $card-border-top;
  }

  .user-activities {
    .activity-type {
      .bi { display: none; } // ...? wtf -  why?

      &.activity-task,
      &.activity-comment,
      &.activity-diary {
        .bi.bi-chat-left-fill { display: block; }
      }
    }
  }
}
@include media-breakpoint-up(md) {
  .user-profile {
    .user-profile-info .card {
      flex-direction: row;

      .card-body { width: calc(100% - 250px); }
      .card-body:first-child { width: 250px; }
    }
  }
}

.user-profile-pointsOverview .reward-storno-message td { background: $danger-100; }