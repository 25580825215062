
                @import "/var/www/echo/releases/255/www/assets/themes/tmobile.scss";
        
.image-note-dialog { display: none; }

.chart .rating-names { display: none; }
.chart .rating-type {
  width: 100%;
  max-width: 100%;
  flex: 0 0 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
  margin-top: 1rem;

  label {
    white-space: nowrap;
    flex-grow: 1;
    text-align: center;
  }
}

.leaflet-popup-content {
  h4 { margin-bottom: 6px; }
  p { margin-top: 6px; }
}

.leaflet-pane {
  z-index: 90;
}
.leaflet-top, .leaflet-bottom {
  z-index: 99;
}
.leaflet-control {
  z-index: 98;
}

.ui-draggable .ui-dialog-titlebar { display: none; }