.dropdown-menu[data-bs-popper] {
  left: unset;
  right: 0;
}
.btn + .dropdown-menu.show { inset: 0 0 auto auto !important; } // !important for overwrite btsp default inline styles

.dropdown-menu.show { @extend .shadow-sm }

.dropdown-menu.notification-list {
  min-width: 250px;
  width: 80%;
  max-width: 350px;
}

.dropdown-menu.dropdown-notifications {
  max-height: calc(100vh - $navbar-height - 80px);
  overflow: auto;
}
@include media-breakpoint-down(sm) {
  .dropdown.user-notification { position: unset; }
  .dropdown-menu.dropdown-notifications {
    width: 100vw;
    top: calc($navbar-height - 2px);
    left: 0;
    border-radius: 0;
    border: none;
    box-shadow: var(--bs-box-shadow-lg) !important;
  }
}