.form-label {
  margin-bottom: 0.25rem;
  color: $secondary;
}
.form-label,
.form-check-label {
  display: block;

  &.required::after {
    content: " *";
    color: $danger;
  }
}