.container-wrapper > .main-nav + .container-fluid.chatbot {
  height: calc(100vh - $navbar-height);
  padding: 0;

  .chatbot-sidebar {
    .nav-link {
      border-radius: $border-radius;
      font-weight: 500;
      color: $body-color;

      &.active { color: $primary; }
      &.active,
      &:hover,
      &:focus { background: $primary-100; }
    }
  }

  .chatbot-start {
    height: calc(100vh - $navbar-height);

    .chatbot-message {
      padding: 50px 0;

      .chatbot-avatar {
        width: 5rem;
        height: 5rem;
      }
    }

    .chatbot-start-form {
      max-width: 600px;

      .chatbot-input { min-height: unset; }
    }
  }
}
.navbar + .navbar + .container-wrapper > .main-nav + .container-fluid.chatbot {
  height: calc(100vh - $navbar-height - $navbar-height);

  .chatbot-start { height: calc(100vh - $navbar-height - $navbar-height); }
}
@include media-breakpoint-up(lg) {
  .container-wrapper > .main-nav + .container-fluid.chatbot .chatbot-sidebar {
    height: calc(100vh - $navbar-height);
    z-index: 111;

    > div:first-child { height: $chatbot-sidebar-height-down-lg; }
    .collapse:not(.show) {
      height: calc(100vh - $navbar-height - $chatbot-sidebar-height-down-lg);
      display: block;
    }
  }
  .navbar + .navbar + .container-wrapper > .main-nav + .container-fluid.chatbot .chatbot-sidebar {
    height: calc(100vh - $navbar-height - $navbar-height);

    .collapse:not(.show) { height: calc(100vh - $navbar-height - $navbar-height - $chatbot-sidebar-height-down-lg); }
  }
}
@include media-breakpoint-down(lg) {
  .container-wrapper > .main-nav + .container-fluid.chatbot {
    .chatbot-sidebar {
      width: calc(100% - $main-nav-width);
      height: $chatbot-sidebar-height-down-lg;
      position: fixed;
      z-index: 111;

      .collapse.show { height: calc(100vh - $navbar-height - $chatbot-sidebar-height-down-lg); }
    }
    .chatbot-conversation {
      height: calc(100vh - $navbar-height - $chatbot-sidebar-height-down-lg);
      margin-top: $chatbot-sidebar-height-down-lg;
      z-index: 100; // to overfllow .main-nav
    }
    .chatbot-start { height: calc(100vh - $navbar-height - $chatbot-sidebar-height-down-lg); }
  }
  .navbar + .navbar + .container-wrapper > .main-nav + .container-fluid.chatbot {
    height: calc(100svh - $navbar-height - $navbar-height);

    .chatbot-sidebar .collapse.show { height: calc(100vh - $navbar-height - $navbar-height - $chatbot-sidebar-height-down-lg); }
    .chatbot-conversation { height: calc(100vh - $navbar-height - $navbar-height - $chatbot-sidebar-height-down-lg); }
    .chatbot-start { height: calc(100vh - $navbar-height - $navbar-height - $chatbot-sidebar-height-down-lg); }
  }
}
@include media-breakpoint-up(md) {
  .container-wrapper > .main-nav + .container-fluid.chatbot { padding: 0 0 0 $main-nav-width; }
}
@include media-breakpoint-down(md) {
  .container-wrapper > .main-nav + .container-fluid.chatbot .chatbot-sidebar { width: 100%; }
}