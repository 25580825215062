.datagrid .datagrid-toolbar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &:last-child { margin-left: 0; }

  > * + * { margin-left: 10px; }

  .popover {
    min-width: 160px;
    padding: 5px 0;
    list-style: none;

    > li > a {
      text-align: left;
      clear: both;
      display: block;
      padding: 3px 20px;
      white-space: nowrap;
    }
  }
}

.datagrid-grid .toolbar-button { padding: 1px 0px 1px 4px; }