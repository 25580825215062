figure.image {
  clear: both;
  margin: auto auto;

  &.image_resized {
    position: relative;
    margin-left: 1.5em;
  }

  &.image-style-block-align-left {
    margin-left: 0;
  }

  &.image-style-block-align-right {
    margin-right: 0;
  }

  > img {
    max-width: 100%;
  }

}

.image-style-align-left {
  float: left;
}

.image-style-align-right {
  float: right;
}
