div[data-db-circle-progress="customerSatisfactionOverall"] {
  max-height: 250px;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
}
@include media-breakpoint-up(lg) {
  div[data-db-circle-progress="customerSatisfactionOverall"] {
    max-height: 150px;
    max-width: 150px;
  }
}

div[data-db-ratings="customerSatisfactionRatings"] {
  div[data-template-container="true"] div {
    @extend .d-flex;
    @extend .flex-row;
    @extend .flex-nowrap;
    @extend .align-items-center;

    svg { margin: 0 0.5rem; }
  }
}

.emotion-chart {
  .emotion-category {
    display: flex;
    flex-wrap: nowrap;

    div {
      width: calc(100% / 3);
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: baseline;
    }

    span {
      display: inline-block;
      width: 11%;
      height: 6px;
      border: 1px solid $gray-300;
      border-bottom: none;

      &.left { border-right: none; }
      &.right { border-left: none; }
    }
  }

  .legend {
    display: flex;
    flex-wrap: nowrap;
  }

  .emotion-wrapper {
    width: calc(100% / 6);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .emotion {
    width: 22px;
    height: 22px;
    margin: 0 auto;
    display: block;
    font-size: 0;
  }

  div[data-db-emotion-chart="emotionChart"] { min-height: 200px; }
}