h1 { font-size: calc(var(--bs-body-font-size) * 2); }
h2 { font-size: calc(var(--bs-body-font-size) * 1.5); }
h3 { font-size: calc(var(--bs-body-font-size) * 1.23); }
h4 { font-size: calc(var(--bs-body-font-size) * 1.18); }
h5 { font-size: calc(var(--bs-body-font-size) * 1.1); }
h6 { font-size: calc(var(--bs-body-font-size) * 1); }

.display-3 {
  padding-bottom: 0.5rem;
  border-bottom: 4px solid $primary;
  display: inline-block;
  font-size: calc(var(--bs-body-font-size) * 1.23);
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  &.required::after {
    content: " *";
    color: $danger;
  }
}

[type="checkbox"] + label[for],
[type="radio"] + label[for] {
  cursor: pointer;
}

blockquote {
  border-left: 5px solid #ccc;
  font-style: italic;
  margin-left: 0;
  margin-right: 0;
  overflow: hidden;
  padding-left: 1.5em;
  padding-right: 1.5em;
}