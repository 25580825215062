.datagrid .header {
  &.disabled { opacity: 0.35; }

  h3 { margin-bottom: 0 !important; }

  .columns {
    margin-bottom: 0.75rem;
    display: flex;
    align-items: center;

    &:nth-child(2) { justify-content: flex-end; }
  }

  .toolbar-buttons {
    display: flex;
    justify-content: flex-end;

    > span {
      display: flex;
      margin-left: 4px;

      &:first-child { margin-left: 0; }
    }

    .btn{
      margin: 0 2px;

      &:first-child { margin-left: 0; }
      &:last-child { margin-right: 0; }


      &.side-drop {
        display: inline-block;
        width: 0;
        padding-left: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        transition: all 350ms ease-out;

        &:hover { width: 140px; }
      }
    }
  }
}

.datagrid-column-header-additions {
  position: absolute;
  opacity: 1;
  z-index: 15;
  margin-top: 5px;

  &:last-child { margin-left: -20px; }

  .dropdown-toggle { display: none; }

  .column-settings-menu {
    right: 0;
    padding: 0.5rem 1.5625rem;
    color: $body-color;
    border: 1px solid $primary-200;
    border-radius: $border-radius;
    background: #FFFFFF;
    box-shadow: $box-shadow-sm;
    min-width: 100px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    &::before,
    &::after {
      display: block;
      width: 0;
      height: 0;
      border: inset 6px;
      content: '';
      border-top-width: 0;
      border-bottom-style: solid;
      position: absolute;
      bottom: 100%;
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      margin-bottom: -1px;
    }
    &::before {
      border: inset 7px;
      border-color: transparent transparent $primary-200;
    }
    &::after {
      border: inset 6px;
      border-color: transparent transparent #FFFFFF;
    }
  }
}

.datagrid [data-submitted-form-spinner] {
  display: none; // spinner moving content (try at user grid)
}
