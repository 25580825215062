
                @import "/var/www/echo/releases/255/www/assets/themes/tmobile.scss";
        
$ico-ai-avatar-variant: "../images/ico-ai-avatar-variant.svg";
$ico-ai-avatar: "../images/ico-ai-avatar.svg";

.openai-head,
.openai-if-head {
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .openai-title,
  .openai-if-title {
    font-weight: 700;
    font-size: 11px;
    line-height: 11px;
    color: $body-color;
    mix-blend-mode: normal;
    opacity: 0.5;
    display: flex;

    .ico-ai-minutes,
    .ico-ai-insightfinder {
      background: url($ico-ai-avatar-variant) no-repeat;
      padding: 10px 12px;
    }
    .title-content {
      padding-top: 2.5px;
    }
    .openai-titlepart,
    .openai-if-titlepart {
      display: flex;
      flex-direction: column;

      .title-sub {
        font-style: italic;
        color: #7d7d7d;
        margin-top: 3px;
      }
    }
  }
  .openai-actions,
  .openai-if-actions {
    i {
      margin-right: 0;
    }
    .popover {
      right: -10px;
      min-width: 200px;

      &::before { right: 2px; }
      &::after { right: 4px; }
    }
  }
  .openai-if-actions {
    .popover {
      &::before { right: 25px; }
      &::after { right: 27px; }
    }
  }
}

.openai-action-expand {
  .bi-arrows-expand { display: none; }

  &.hide-area {
    .bi-arrows-expand { display: block; }
    .bi-arrows-collapse { display: none; }
  }
}

.popover.info-help {
  margin-top: 25px;
  padding: 15px
}

.openai-content,
.openai-if-content {
  position: relative;
}

.openai-content-info,
.openai-remove-info {
  font-size: 11px;
  font-style: italic;
  text-align: center;
}

.openai-content-sharecontainer {
  text-align: center;

  .openai-content-sharetitle {
    font-weight: 700;
    margin-bottom: 5px;
  }

  button.openai-share-emailbtn,
  button.openai-share-cancelbtn {
    margin-top: 5px;
  }

  .openai-content-shareerror {
    color: $danger;
    margin: 5px 3px;
  }

  .openai-content-responses-template {
    margin: 10px 0;
  }
}

.openai-content-default,
.openai-if-content-default {
  font-style: italic;
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;
}

.openai-content-error,
.openai-if-content-error,
.openai-action-copycontentinfo,
.openai-content-refreshinfo {
  color: $danger;
  font-style: normal;
}

.openai-question-container {
  margin-bottom: 10px;
}

.openai-content-generatebtn {
  text-align: center;

  .openai-generate-btn,
  .openai-send-btn {
    width: 100%;
  }
}

.openai-content-responses .openai-content-responses-template {
  margin-top: 10px;
}

.openai-content-responses-template {
  display: flex;

  .openai-avatar-ico {
    background: url($ico-ai-avatar) no-repeat;
    display: inline-block;
    width: 44px;
    height: 44px;
  }

  .openai-if-content-container {
    padding: 0 !important;
    width: 100%;

    .openai-content-desc {
      padding: 5px 7px !important;

      .openai-if-content-text {
        padding: 4px;

        &.question {
          font-weight: bold;
          position: relative;
          padding-right: 18px;
        }

        .ico-ai-duplicate {
          padding-left: 14px;
          margin: 0;
          background-size: contain;
          position: absolute;
          height: 20px;
          right: 5px;
          cursor: pointer;
          top: 50%;
          transform: translate(0, -50%);
        }
      }
    }
  }

  .openai-content-container,
  .openai-if-content-container {
    padding-left: 7px;
    position: relative;

    .openai-content-arrow {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7px 12px 7px 0;
      border-color: transparent #F4F3F9 transparent transparent;
      position: absolute;
      left: -4px;
      top: 15px;
    }

    .openai-content-desc {
      padding: 10px 15px;
      background: #F4F3F9;
      border-radius: 6px;
      color: #393939;

      .openai-if-content-text,
      .openai-content-text {
        &.question {
          background-color: #fff;
          border-radius: 6px 6px 0 0;
        }
        &.answer {
          border-radius: 0 0 6px 6px;
          border: #999;
        }

        .openai-if-content-description,
        .openai-content-description {
          max-height: 300px;
          overflow: hidden;
          position: relative;

          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 4rem;
            background-image: linear-gradient(0deg, rgba(244,243,249,1) 5%, rgba(244,243,249,0) 100%);
          }

          p {
            margin-bottom: 0;
          }
          span {
            display: inline;
          }

          .read-more-text {
            display: none;
          }

          &.full {
            &::after {
              content: none;
            }
            max-height: unset;

            .read-more-text {
              display: inline;
            }
            .read-more-dots {
              display: none;
            }
          }
        }
      }

      .openia-content-time-container {
        display: flex;
        margin-top: 10px;

        .openai-content-time {
          flex: 1;
          color: #393939;
          mix-blend-mode: normal;
          opacity: 0.5;
          text-align: left;
        }
        .openai-action-share {
          height: 100%;
          background-position: center;
        }
      }
    }
  }
}

.openai-content-showmore {
  margin-top: 10px;
  text-align: center;
}