html {
  scroll-padding-top: 34px; /* height of sticky header */
}

.navbar {
  width: 100%;
  height: $navbar-height;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  + .navbar { z-index: 900; }
}
.navbar.navbar-project-info {
  top: $navbar-height;
  justify-content: center;
  font-weight: 700;
  color: $white;

  &.navbar-status-prepared { @extend .bg-info; }
  &.navbar-status-finished { @extend .bg-secondary; }
  &.navbar-status-deleted { @extend .bg-danger; }

  & + .container-wrapper {
    padding: 2*$navbar-height 0 0 0;

    @include media-breakpoint-up(md) {
      & > .main-nav {
        height: calc(100vh - 2*$navbar-height);
        top: 2*$navbar-height;
        padding: 1rem 0;
      }
    }
  }
}

.container-wrapper {
  @extend .container-fluid;

  padding: $navbar-height 0 0 0;
  display: flex;
  flex-direction: column;
}
@include media-breakpoint-up(md) {
  .container-wrapper {
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

.container-wrapper > .main-nav {
  width: 100%;
  position: fixed;
  left: 0;
  z-index: 2;

  + .container-fluid {
    padding: 2rem;
    overflow: auto;

    .sidebar {
      > * { margin-bottom: 1rem; }
      > *:last-child { margin: 0; }
    }
  }
}
@include media-breakpoint-down(sm) {
  .container-wrapper > .main-nav + .container-fluid { padding: 1rem 0.75rem; }
}
@include media-breakpoint-down(md) {
  .container-wrapper > .main-nav {
    bottom: 0;

    + .container-fluid {
      max-height: calc(100vh - $navbar-height);
      padding-bottom: calc(100px + 1rem);
    }
  }
  .navbar + .navbar + .container-wrapper > .main-nav + .container-fluid { max-height: calc(100vh - 2*$navbar-height); }
}
@include media-breakpoint-up(md) {
  .container-wrapper > .main-nav {
    max-width: $main-nav-width;
    height: calc(100vh - $navbar-height);
    top: $navbar-height;
    overflow-x: hidden;
    overflow-y: auto;

    + .container-fluid { padding-left: calc($main-nav-width + 2rem); }
  }
}
@include media-breakpoint-down(lg) {
  .container-wrapper > .main-nav  + .container-fluid .sidebar { margin-top: 1.5rem; }
}