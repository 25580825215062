.datagrid {
  padding: 0;
  background-color: transparent;

  table {
    border-collapse: collapse;

    tr {
      th, td {
        padding: 0.5rem $card-padding-y;
        border: 1px solid $gray-100;
      }
    }

    thead {
      tr {
        th {
          border-bottom-width: 2px;
          vertical-align: middle;

          &.header {
            padding: 0;
            text-align: left;
            background-color: $gray-100;

            h3 {
              display: inline-block;
              margin-bottom: 0.5rem;
            }
          }

          .group-actions {
            display: none;

            select { width: auto; }
          }

          select { margin-bottom: 0; }
          a { text-decoration: none; }
          .btn-outline-primary:not(:hover),
          .btn-outline-secondary:not(:hover),
          .btn-outline-danger:not(:hover) { background: $white; }
        }

        &.table-header { border-top: $card-border-top; }
        &.row-group-actions th { background-color: $gray-100; }
      }
    }

    tbody {
      tr:hover,
      tr:focus { background-color: $primary-100; }
      tr.invalid td { background: $danger-100; }
      tr.non-valid td { background: $info-100; }
    }

    tfoot select {
      margin: 0;
      padding: 0.25rem 1.5rem 0.25rem 0.5rem;
    }

    td.col-checkbox,
    th.col-checkbox { padding-top: 0.5rem; }
  }

  .row-grid-bottom {
    background-color: #FFFFFF;
    border: none;

    .col-pagination { font-size: $font-size-base; }
    .col-pagination a.active { font-weight: bold; }
  }

  .group-actions {
    button,
    .btn {
      margin-bottom: 3px;
    }
  }
}

.datagrid .clipboard-wrapper {
  width: 190px;
  display: flex;
  white-space: nowrap;

  .clipboard-source {
    height: 100%;
    background-color: $gray-100;
  }
  .clipboard-btn {
    padding: 0 4px;
    margin: 0;
    width: 26px;
    height: 33px;
    line-height: 33px;
    position: relative;
    bottom: 33px;
    left: 164px;
    border-radius: 0;
  }
}

.outer-filters{
  display: flex;
  align-items: center;

  input {
    &::-webkit-input-placeholder { /* Edge */
      color: $primary;
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $primary;
    }

    &::placeholder {
      color: $primary;
    }
  }
  select{
    margin: 0;
    border: none;
    height: 100%;
    cursor: pointer;
    color: $primary;
    overflow-y: auto;
    option {
      height: 2rem;
    }
    &[multiple] {
      option[selected]{
        color: red;
      }
    }
  }

  &.filter-select {
    position: relative;
    padding: 0 !important;

    select {
      padding: 0 1.5rem 0 1rem;
    }

    select + i.bi {
      pointer-events: none;
      position: absolute;
      right: 5px;
    }
  }
}
.outer-filters ~ .submit {
  float: right;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;

  button {
    height: 100%;
    border-radius: 0;
  }
}
