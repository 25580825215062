.bg-144, .bg-214, .bg-281, .bg-282, .bg-356, .bg-560, .bg-575, .bg-681, .bg-682, .bg-696, .bg-697, .bg-699, .bg-722, .bg-723, .bg-729, .bg-740, .bg-799, .bg-800, .bg-801 {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom right;
}

.bg-144 { background-image: url("../images/project-backgrounds/bg-144.png"); }
.bg-214 { background-image: url("../images/project-backgrounds/bg-214.png"); }
.bg-281 { background-image: url("../images/project-backgrounds/bg-281.png"); }
.bg-282 { background-image: url("../images/project-backgrounds/bg-282.png"); }
.bg-356, .bg-560 { background-image: url("../images/project-backgrounds/bg-356.png"); }
.bg-575, .bg-729 { background-image: url("../images/project-backgrounds/bg-575.png"); }
.bg-681 { background-image: url("../images/project-backgrounds/bg-681.png"); }
.bg-682 { background-image: url("../images/project-backgrounds/bg-682.png"); }
.bg-696 { background-image: url("../images/project-backgrounds/bg-696.png"); }
.bg-697 { background-image: url("../images/project-backgrounds/bg-697.png"); }
.bg-699 { background-image: url("../images/project-backgrounds/bg-699.png"); }
.bg-722 { background-image: url("../images/project-backgrounds/bg-722.png"); }
.bg-723 { background-image: url("../images/project-backgrounds/bg-722.png"); }
.bg-740 { background-image: url("../images/project-backgrounds/bg-740.png"); background-color: #ECF5EE !important; }
.bg-799 { background-image: url("../images/project-backgrounds/bg-799.svg"); }
.bg-800 { background-image: url("../images/project-backgrounds/bg-800.svg"); }
.bg-801 { background-image: url("../images/project-backgrounds/bg-801.svg"); }

.bg-105:before {
  content: '';
  background-image: url("../images/project-backgrounds/illustration.svg");
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.5;
  width: 500px;
  height: 350px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: -1;
}