.card {
  .user-info {
    padding: 20px;
    background: $white;

    .avatar {
      float: left;
      margin-right: 15px;
      border-radius: 50%;
    }
    .name {
      margin-top: 4px;
      font-size: 1rem;
      font-weight: 600;
    }
    .email {
      color: $gray-600;
      font-size: 0.8rem;
    }
    .row {
      margin-left: 0;
      margin-right: 0;
    }
    .label { font-weight: 600; }
    .text {
      margin-top: 25px;
      padding: 10px;
      background: $gray-200;
      border: 1px solid $primary;
    }
  }
  &.user-project-attributes {
    .attribute {
      &:not(:first-child) { padding-top: 20px; }

      &:not(:last-child) {
        padding-bottom: 20px;
        border-bottom: 1px solid $gray-600;
      }

      .label { font-weight: 600; }
    }
  }

  .user-project-attributes-form {
    .control-label { font-weight: 600; }
    label { margin-top: 0; }
    .row { margin-top: 1.25rem; }
    form .row:first-child { margin-top: 0; }
    [type='file'], [type='checkbox'], [type='radio'] { margin-bottom: 0; }

    .dynamic-attributes {
      label:not(:first-child) {
        margin-top:.5em;
        display: block;

        & input {
          margin-right:1em;
        }
      }
    }
  }

  .user-form {
    .password-change { margin-top: 2rem; }
    .reveal-link.revealed, .revealed-detail { display: none; }
    .revealed-detail h3 { margin-bottom: 0; }
  }
}