
                @import "/var/www/echo/releases/255/www/assets/themes/tmobile.scss";
        
.chatbot {
  .chatbot-conversation {
    background: $chatbot-conversation-bg;

    .chatbot-conversation-detail {
      position: relative;

      .avatar {
        width: 1rem;
        height: 1rem;
      }

      .message {
        width: 100%;
        border-radius: 0 1rem 1rem 1rem;
        background: $white;
        max-width: 800px;

        p,
        ol,
        ul {
          margin-bottom: 0.5rem;
        }
        img {
          max-width: 100%;
        }

        .message-attachments {
          .images {
            img {
              height: 120px;
              width: 120px;
              transition: box-shadow 0.2s;

              &:hover {
                box-shadow: $box-shadow-sm;
              }
            }
          }
        }
      }

      .user-message {
        width: calc(100% - 1rem - 0.5rem); // 1rem = avatar width; 0.5rem = gap

        .message {
          border-radius: 1rem 0 1rem 1rem;
          background: $primary-100;
          white-space: pre-wrap;
        }
      }
    }

    .chatbot-scroll-wrapper {
      overflow: auto;
      padding: 1rem 1rem 6rem 1rem; // 6rem = .chatbot-input max height (7 rem) - 1rem
      height: calc(100vh - $navbar-height - $chatbot-sidebar-height-down-lg - $chatbot-inputarea-height);
    }

    .chatbot-conversation-wrapper {
      max-width: 970px;
      margin-inline: auto;

      > .chatbot-animate-message {
        animation-fill-mode: both;
        animation-timing-function: ease-out;
        animation-duration: 312ms;
        animation-name: anim-fade-in-up;
        transform-origin: top;
        animation-delay: 300ms;

        @for $i from 1 through 5 {
          &:nth-last-child(#{$i}) {
            animation-delay: -$i * 50ms + 50ms;
          }
        }
      }

      @keyframes anim-fade-in-up {
        0% {
          opacity: 0;
          translate: 0 80px;
        }
        100% {
          opacity: 1;
          translate: 0 0;
        }
      }

    }

    .chatbot-input {
      width: 100%;
      min-height: $chatbot-inputarea-height;
      position: absolute;
      bottom: 0;
      z-index: 110;

      textarea.auto-resize {
        min-height: 40px;
        max-height: 5rem;
        padding: 9px 0.75rem;
        resize: none;
        overflow: auto;
      }

      textarea + .btn {
        width: 40px;
        height: 40px;
      }
    }
    .chatbot-think {
      padding-left: 2rem;
      margin-top: -1rem;

      > span {
        display: inline-block;
        animation-name: anim-jump-25-50;
        animation-duration: 0.75s;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
      }
      > span:nth-child(1) { animation-delay: 0.15s; }
      > span:nth-child(2) { animation-delay: 0.3s; }
      > span:nth-child(3) { animation-delay: 0.45s; }
      @keyframes anim-jump-25-50 {
        0%  { translate: 0   0;  }
        5%  { translate: 0   3%; }
        30% { translate: 0 -15%; }
        55% { translate: 0   3%; }
        60% { translate: 0   0;  }
      }
    }
  }

  .image-detail {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    img {
      max-width: 100%;
      max-height: min(100%, calc(100vh - 120px));
      box-shadow: $box-shadow-lg;
      z-index: 200;
    }
  }

  .image-detail {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    img {
      max-width: 100%;
      max-height: min(100%, calc(100vh - 120px));
      box-shadow: $box-shadow-lg;
      z-index: 200;
    }
  }
}

@include media-breakpoint-up(lg) {
  .chatbot .chatbot-conversation .chatbot-scroll-wrapper {
    height: calc(100vh - $navbar-height - $chatbot-inputarea-height);
  }
}

@include media-breakpoint-up(md) {
  .chatbot .chatbot-conversation {
    .chatbot-conversation-detail {
      .avatar {
        min-width: 2rem;
        height: 2rem;
      }

      .chatbot-message {
        width: calc(100% - 2rem - 0.5rem); // 2rem = avatar width; 0.5rem = gap
      }

      .user-message {
        width: calc(100% - 2rem - 0.5rem); // 2rem = avatar width; 0.5rem = gap
      }
    }

    .chatbot-input {
      width: calc(100% - $main-nav-width);
    }

    .chatbot-think {
      padding-left: 3rem;
    }
  }
}

.chatbot-avatar {
  background-image: url("../../../images/chatbot/tina-avatar-light.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

@media screen and (prefers-color-scheme: dark) {
  .chatbot-avatar {
    background-image: url("../../../images/chatbot/tina-avatar-dark.svg");
  }
}

