.sign-in {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .app-logo {
    width: 100%;
    max-width: 220px;
    height: 40px;
    margin: 1rem auto 3rem auto;
  }

  .card {
    width: 100%;
    max-width: 380px;
    margin-bottom: 1rem;
  }
  .card-body { padding: 1rem 2rem; }
  @include media-breakpoint-down(sm) {
    .card-body { padding: $card-padding; }
  }
  .card-footer {
    padding: $card-padding;
    border: none;
    background: $white;
  }

  .help-link {
    margin-top: 2rem;
    display: block;
    font-weight: 600;
    text-decoration: none;

    &:hover { text-decoration: underline; }
  }

  .languages {
    margin-bottom: 1rem;
    text-align: center;

    a {
      margin: 0.375rem;
      display: inline-block;
    }
    img { width: 1.5rem; }
  }

  .copyright {
    color: $secondary;
    font-size: 0.75rem;
  }
}

.forgotten-password,
.password-reset,
.password-setup,
.redirect { @extend .sign-in; }