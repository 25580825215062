.cursor-pointer { cursor: pointer; }
.cursor-move { cursor: move; }
.border-solid { border-style: solid; }
.mt-n2 { margin-top: -0.5rem !important; }

.p-2px { padding: 2px; }

.start-5 { left: 2.5rem !important; }

.top-75 { top: 75%; }
.start-75 { left: 75%; }

.fs-7 { font-size: 0.875rem !important; }
.fs-8 { font-size: 0.75rem !important; }
.fs-9 { font-size: 0.615rem !important; }


// Colors
.bg-green-50 { background: rgba($green-100, 0.5) !important; }
.bg-green-100 { background: $green-100 !important; }

.bg-info-50 { background: rgba($info-100, 0.5) !important; }
.bg-info-100 { background: $info-100 !important; }
.bg-info-200 { background: $info-200 !important; }

.bg-red-50 { background: rgba($red-100, 0.5) !important; }
.bg-red-100 { background: $red-100 !important; }

.bg-primary-50 { background: rgba($primary-100, 0.5) !important; }
.bg-primary-100 { background: $primary-100 !important; }

.bg-yellow-50 { background: rgba($yellow-100, 0.5) !important; }
.bg-yellow-100 { background: $yellow-100 !important; }

.text-gold { color: $gold !important; }

.text-gray-400 { color: $gray-400 !important; }
.text-gray-500 { color: $gray-500 !important; }


$utilities: map-merge(
  $utilities,
  (
    "cursor": (
      property: cursor,
      values: auto default pointer wait text move help not-allowed
    ),
  )
);

// Shame
span[data-submitted-form-spinner] {
  margin: 0;

  + .bi { display: none; }
}
hr {
  border-top: $card-border-divider;
  opacity: 1;
}
.avatar {
  width: 2rem;
  height: 2rem;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}
div#snippet-videoCallInfoPanel-:empty { display: none; }
div#snippet-discussion-commentForm { padding: 0; }


.tag {
  @extend .badge;
  @extend .text-dark;
  @extend .fw-normal;

  max-width: 100px;
  margin: 0.125rem;
  overflow: hidden;
  text-overflow: ellipsis;

  @include media-breakpoint-down(lg) { max-width: 60px; }
}
.tag-color-1 { background: $gray-100; }
.tag-color-2 { background: $red-200; }
.tag-color-3 { background: $yellow-100; }
.tag-color-4 { background: $green-100; }
.tag-color-5 { background: $purple-100; }
.tag-color-6 { background: $blue-100; }
.tag-color-7 { background: $pink-100; }
.tag-color-8 { background: $orange-100; }
