// This file is imported at beginning every entry scss file

// Custom color palette
$primary: #E20074;
$info: #FE9309; // for premium and tasks

// Vars
@import "./common"; // import bootstrap variables and custom vars

// App logo
$logo-top: "../themes/images/tmobile/logo-top-symbol.svg"; // it appears in the upper left corner in the navbar container

// Font-family
@font-face {
  font-family: 'TeleNeoWeb-Regular';
  src: url('fonts/tmobile/TeleNeoWeb-Regular.eot'),
  url('fonts/tmobile/TeleNeoWeb-Regular.woff') format('woff');
}
$font-family-sans-serif: TeleNeoWeb-Regular, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

// Theme colors
$body-color: $gray-900; // default color for all text
$body-tertiary-bg: #FAFAFA; // .bg-body-tertiary (body bg, datagrid rows bg, private messages bg etc.)
$navbar-bg: $primary; // .navbar.top background
$navbar-brand-color: $white; // .navbar-brand text
$navbar-link-color: $primary-200; // .navbar.top .nav-link text
$navbar-link-icon-color: $white; // .navbar.top .navbar-nav > .nav-item > .nav-link > i
$main-nav-bg: $white; // .container-wrapper > .main-nav
$main-nav-link-color: $gray-700; // .container-wrapper > .main-nav .nav-item .nav-link
$main-nav-link-active-color: $primary; // .container-wrapper > .main-nav .nav-item .nav-link.active, &:hover, &:focus
$main-nav-link-active-background: $gray-100; // .container-wrapper > .main-nav .nav-item .nav-link.active, &:hover, &:focus
$main-nav-header-color: $body-color; // .container-wrapper > .main-nav .nav-header
$nav-divider: $gray-300; // .nav .nav-divider
$filter-icon-bg: $primary-800; // filter icon background in filter containers
$filter-icon-color: $white; // filter icon color in filter containers
$form-check-input-border: $primary; // .form-check-input border-color
$card-guide-bg: linear-gradient(143.85deg, $primary-500 7.26%, $blue-900 90.9%), $primary; // .card-guide.bg-gradient
$card-bg-default: $white; // default bg color for all cards
$finishedTask-color: $blue-700; // .card-finishedTask border, .card-finishedTask img + span color
$diary-color: $success; //.card-diary border

// Custom styles
.top-bar .app-name img {
  height: 32px;
  padding: 0;
}
.sign-in,
.sign-up,
.forgotten-password,
.password-reset,
.password-setup,
.redirect {
  .app-logo { height: 50px; }
}