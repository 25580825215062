.structured-discussion {
  .structured-discussion-item {
    padding: $card-padding-sm;

    &:not(:first-child) { @extend .border-top; }

    &.uncomplete { border-left: solid 3px $info; }
    &.canceled { border-left: solid 3px $danger; }
  }
}

.discussion-detail {
  figure.media {
    max-height: 315px; // Fix video size on large screen
    overflow: hidden;
  }
}

.post-author { font-weight: 500; }

.card.discussion-comment {
  box-shadow: unset;
  border-left: solid 0 $gray-300;

  .card-header {
    @extend .border-top;

    border-bottom: none;

    .comment-author { font-weight: 500; }
  }

  // Comment lvls
  .card.discussion-comment { border-left: solid 1vw $gray-300; } // delete after setup comment lvls
  &.comment-lvl-1 { border-left-width: 0; }
  &.comment-lvl-2 { border-left-width: 1vw; }
  &.comment-lvl-3 { border-left-width: 2vw; }
  &.comment-lvl-4 { border-left-width: 3vw; }
  &.comment-lvl-5 { border-left-width: 4vw; }
  &.comment-lvl-6 { border-left-width: 5vw; }
  &.comment-lvl-7 { border-left-width: 6vw; }
  &.comment-lvl-8 { border-left-width: 7vw; }
  &.comment-lvl-9 { border-left-width: 8vw; }
  &.comment-lvl-10 { border-left-width: 9vw; }
}

.PollFormExtension label.upload-label {
  > input,
  &.has-file > span,
  &:not(.has-file) > img { display: none; }
}

@include media-breakpoint-down(md) {
  .discussion-module > .row {
    > .col-12 { order: 1; }
    > .col-12.col-lg-8.col-xl-9.mb-3 { order: 3; }
    > .sidebar {
      order: 2;
      margin: 0 0 1rem 0 !important;
    }
  }
}