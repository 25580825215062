.sign-up {
  @extend .sign-in;

  .card { max-width: 500px; }
  .card-header { padding: $card-padding-y 2rem; }
  @include media-breakpoint-down(sm) {
    .card-header { padding: $card-padding-y $card-padding; }
  }

  .g-recaptcha { display: none; }
}