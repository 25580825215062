.chatbot-icon {
  position: fixed;
  bottom: 6rem;
  right: 1rem;
  z-index: 120;
  @include media-breakpoint-up(md) {
    bottom: 1rem;
  }

  .chatbot-avatar {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;

    .spinner-border {
      display: none !important;
    }
  }

  .message {
    width: 100%;
    max-width: 250px;
    position: relative;
    border-radius: 1.25rem;

    .arrow {
      width: 1rem;
      height: 0.5rem;
      position: absolute;
      bottom: -0.75rem;
      right: 1rem;
      border: solid 0.5rem transparent;
      border-top-color: $primary;
    }
  }
}