.file-image {
  cursor: pointer;
  max-height: 30px;
  min-width: 20px;
}

.user-full-form {

  .user-avatar-area {
    position: relative;

    &>a {
      opacity: 0;

      .upload-area .upload-area-icons .bi-plus {
        display: none;
      }
      .upload-area .upload-area-icons .bi-pen {
        display: block !important;
      }
      .upload-area .upload-area-link {
        display: none;
      }
    }
  }

  .user-avatar-area a:hover {
    opacity: 0.5;
  }

  .user-avatar-area .user-avatar-image {
    width: 120px;
    height: 120px;
    position: absolute;
    top: 0;
    left: 0;

    img,
    canvas{
      border-radius: 20px;
    }
  }

  .upload-area {
    width: 120px;
    height: 120px;
  }
}

.user-avatar-area.active {
  img,
  canvas{
    max-width: 170px;
    max-height: 170px;
    position: absolute;
    border-radius: 20px;
  }

  &>a {
    opacity: 0;

    .upload-area .upload-area-icons .bi-plus {
      display: none;
    }
    .upload-area .upload-area-icons .bi-pencil {
      display: block !important;
    }
    .upload-area .upload-area-link {
      display: none;
    }
  }
  &>a:hover {
    opacity: 0.5;
  }
}

.upload-area {
  box-sizing: border-box;

  width: 170px;
  height: 170px;
  float: left;
  position: relative;

  background: $light;
  border: 1px dashed $primary-100;
  border-radius: 20px;

  color: $primary;
  padding: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;

  &:hover { text-decoration: underline; }
}