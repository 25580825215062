.btn {
  display: inline-flex;
  flex-direction: revert;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  font-weight: 500;

  > * { margin-left: 0.5rem; }
  > *:first-child { margin: 0; }

  span {
    flex-grow: 1;
    text-align: left;
  }

  &.btn-sm {
    padding: 3px 7px;

    i { font-size: 13px; }
  }

  &.btn-flat-primary {
    .btn-like-counter {
      color: $secondary;
      transition: color 150ms ease-in-out;
    }

    &:hover .btn-like-counter,
    &:focus .btn-like-counter { color: $white; }
  }

  .spinner-border {
    width: 13px;
    height: 13px;
  }
}

button[type="submit"],
input[type="submit"] {
  //.spinner-border { margin-right: 0.5rem; }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} { background: $white; }
}
.btn-outline-secondary { --bs-btn-hover-color: #FFFFFF; } // $white doesnt work - why?
.btn-outline-light { background: transparent; }

// scss-docs-start btn-flat-variant-mixin
@mixin button-flat-variant(
  $color,
  $color-hover: color-contrast($color),
  $active-background: $color,
  $active-border: $color,
  $active-color: color-contrast($active-background)
) {
  --#{$prefix}btn-color: #{$color};
  --#{$prefix}btn-bg: #{$btn-flat-bg};
  --#{$prefix}btn-border-color: #{$btn-flat-border};
  --#{$prefix}btn-hover-color: #{$color-hover};
  --#{$prefix}btn-hover-bg: #{$active-background};
  --#{$prefix}btn-hover-border-color: #{$btn-flat-border};
  --#{$prefix}btn-focus-shadow-rgb: #{to-rgb($color)};
  --#{$prefix}btn-active-color: #{$active-color};
  --#{$prefix}btn-active-bg: #{$active-background};
  --#{$prefix}btn-active-border-color: #{$btn-flat-border};
  --#{$prefix}btn-active-shadow: #{$btn-active-box-shadow};
  --#{$prefix}btn-disabled-color: rgba(0, 0, 0, 0.5);
  --#{$prefix}btn-disabled-bg: #{$btn-flat-bg};
  --#{$prefix}btn-disabled-border-color: #{$btn-flat-border};
  --#{$prefix}gradient: none;
}
// scss-docs-end btn-flat-variant-mixin

@each $color, $value in $theme-colors {
  .btn-flat-#{$color} {
    @include button-flat-variant($value);
  }
}