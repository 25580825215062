
                @import "/var/www/echo/releases/255/www/assets/themes/tmobile.scss";
        
/* Flatpicker-calendar LESS
/*
/* xxx
/*
/* 1. GENERAL
*/

/*****************************/
/*        1. GENERAL         */
/*****************************/
.flatpickr-input {
  ~ label {
    pointer-events: none;
  }
}
.flatpickr-current-month input.cur-year{
  min-height: auto;
}
.flatpickr-current-month .flatpickr-monthDropdown-months{
  height: auto;
  min-height: auto;
}
.flatpickr-months .flatpickr-month{
  height: 37px;
}
.flatpickr-input + input[readonly] {
  cursor: pointer;
  background-color: inherit;
}
.flatpickr-input + input[readonly]:disabled {
  background-color: #e6e6e6;
  cursor: not-allowed;
}
