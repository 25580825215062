
                @import "/var/www/echo/releases/255/www/assets/themes/tmobile.scss";
        
.import-download-template, .import-upload-template {
  h3 {
    color: black;
    margin-bottom: 20px;
    text-transform: none;
  }

  .info-box.icon-idea {
    background-repeat: no-repeat;
    background-position: 15px center;
    padding-left: 40px;
  }

  .step {
    display: none;

    &.step-default { display: block; }
  }
}

.import-upload-template {
  .step {
    &.step-error,
    &.step-error2 { display: block; }
  }
}
