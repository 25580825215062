
                @import "/var/www/echo/releases/255/www/assets/themes/tmobile.scss";
        
.autocomplete-container {
  position: relative;

  ul.autocomplete-list {
    position: absolute;
    width: 100%;
    background: #fff;
    border: 1px solid #efefef;
    padding: 0;
    margin: 0;
    list-style: none;

    li.autocomplete-item {
      padding: .4em 1em;
      border: 1px solid #efefef;

      &:hover {
        background-color: #efefef;
        cursor: pointer;
      }
    }
  }
}

.projects-roles,
.project-roles-legend {
  .label {
    padding: 7px;
    color: #fff;
    display: inline-block;
    margin: 3px;

    > div > div,
    div[class^='small-'],
    div[class*=' small-'] {
      display: flex;
      align-items: center;
    }

    .row {
      width: 100%;
    }

    .access-list {
      padding-top: 5px;
      display: none;

      &.active {
        display: flex;
      }

      > div {
        flex-direction: column;
      }
    }

    [class^='toggle-'],
    [class*=' toggle-'] {
      display: none;

      &.active {
        cursor: pointer;
        display: flex;
      }
    }

    .project-role-remove,
    .project-pageaccess-remove,
    .project-pageaccess-add {
      padding: 0 0 0 6px;
      cursor: pointer;
      justify-content: center;
      text-align: center;

      &:hover {
        color: $danger;
      }
    }

    &.project-role-moderator {
      background-color: $primary;
    }
    &.project-role-user {
      background-color: $danger;

      .project-role-remove:hover {
        color: $primary;
      }
    }
    &.project-role-client {
      background-color: $success;
    }

  }
}
.project-roles-legend {
  .columns {
    display: flex;
    line-height: 100%;

    .legend-title {
      display: inline-block;
      margin: 3px;
      line-height: 100%;
    }
  }
}
.project-roles-error {
  display: none;
  color: $danger;

  &.show {
    display: block;
  }
}