.navbar.top {
  background: $navbar-bg;

  .navbar-brand {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
    color: $navbar-brand-color;

    > * { margin-right: 0.5rem; }
    > *:last-child { margin: 0; }

    img { height: 24px; }

    span {
      max-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    @media screen and (min-width: 400px) {
      span { max-width: 30vw; }
    }
    @include media-breakpoint-up(sm) {
      span { max-width: 40vw; }
    }
    @include media-breakpoint-up(md) {
      span { max-width: 50vw; }
    }
    @include media-breakpoint-up(lg) {
      span { max-width: 70vw; }
    }
  }

  .nav-link {
    color: $navbar-link-color;

    > i { font-size: 1.25rem; }
  }

  .logo-app {
    width: 120px;
    height: 24px;
    display: inline-block;
    vertical-align: middle;
    background: url("../" + $logo-top) no-repeat;
    background-size: contain;
  }

  .navbar-nav > .nav-item > .nav-link {
    > i {
      color: rgba($navbar-link-icon-color, 0.5);
      transition: all 80ms ease-out;
    }
    &:hover > i,
    &:focus > i { color: $navbar-link-icon-color; }
  }

  .user-menu {
    display: flex;
    align-items: center;
    background: transparent;
    border-radius: 1rem;

    .avatar { margin: 0; }
  }
}