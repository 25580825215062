.modal-content {
  max-height: calc(100vh - 2 * var(--bs-modal-margin));
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-footer {
  justify-content: start;
  flex-wrap: nowrap;
  align-items: normal;

  .btn {
    align-items: center;
    text-align: left;
  }
}

.modal-points {
  .modal-dialog { max-width: 300px; }
  .modal-body h1 { margin-top: -38px; }
}