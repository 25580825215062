.create-tag-form {
  .tag-label {
    span {
      border: 1px solid transparent;
      transform-origin: bottom;
      transition: scale 150ms, border-bottom-color 150ms, border-left-color 150ms 20ms, border-top-color 150ms 40ms, border-right-color 150ms 60ms;

      &:hover,
      &:focus { scale: 1.1; }
    }
    input:checked ~ span {
      border: 1px solid $body-color;
      scale: 1.1;
    }
  }
}