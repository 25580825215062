.form-group {
  margin-bottom: 1rem;

  > .btn { min-width: 100px; }
}

.form-description {
  @extend .text-muted;

  margin: -0.25rem 0 0.5rem 0;
  display: block;
  font-size: 0.688rem;
}

.form-group {
  --bs-border-width: 1px; // change preset border-width
  --bs-border-color: #dee2e6; // change preset border-color

  .ui-widget.ui-widget-content {
    width: 100%;
    border: none;
    border-radius: unset;
  }
  .ui-spinner-input {
    margin: 0;
    padding-right: 0;
  }
  .ui-spinner a.ui-spinner-button { border: var(--bs-border-width) solid var(--bs-border-color); }

  .iti {
    position: relative;
    display: block;
  }
}

.form-control-disabled {
  @extend .form-control;
  pointer-events: none;
  background-color: var(--bs-secondary-bg);
  opacity: 1;
  border: 1px solid var(--bs-border-color);
}

label.required::after {
  content: "\00a0*";
  color: $danger;
}

label.has-error { color: $danger !important; }
.has-error:not(label),
.has-error:focus:not(label) {
  border-color: $danger;
}

@include media-breakpoint-down(sm) {
  .users-columns-list {
    -moz-column-count: 1;
    -moz-column-gap: normal;
    -webkit-column-count: 1;
    -webkit-column-gap: normal;
    column-count: 1;
    column-gap: normal;
  }
}
@include media-breakpoint-down(md) {
  .users-columns-list {
    -moz-column-count: 2;
    -moz-column-gap: 20px;
    -webkit-column-count: 2;
    -webkit-column-gap: 20px;
    column-count: 2;
    column-gap: 20px;
  }
}
@include media-breakpoint-up(md) {
  .users-columns-list {
    -moz-column-count: 4;
    -moz-column-gap: 20px;
    -webkit-column-count: 4;
    -webkit-column-gap: 20px;
    column-count: 4;
    column-gap: 20px;
  }
}
