// Overwrite bootstrap preset
$font-size-base: 0.8125rem; // 1rem=16px
$box-shadow-sm: 0 0 5px 1px rgba(0,0,0,.11);

// Bootstrap variables, mixins etc.
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// Colors
$gold: #FFD015; /* Stars, etc. */

$primary-500: $primary;
$primary-100: mix(white, $primary-500, 90%);
$primary-200: mix(white, $primary-500, 70%);
$primary-300: mix(white, $primary-500, 50%);
$primary-400: mix(white, $primary-500, 30%);
$primary-600: mix(black, $primary-500, 30%);
$primary-700: mix(black, $primary-500, 50%);
$primary-800: mix(black, $primary-500, 70%);
$primary-900: mix(black, $primary-500, 80%);

$info-500: $info;
$info-100: mix(white, $info-500, 90%);
$info-200: mix(white, $info-500, 70%);
$info-300: mix(white, $info-500, 50%);
$info-400: mix(white, $info-500, 30%);
$info-600: mix(black, $info-500, 30%);
$info-700: mix(black, $info-500, 50%);
$info-800: mix(black, $info-500, 70%);
$info-900: mix(black, $info-500, 80%);

$danger-500: $danger;
$danger-100: mix(white, $danger-500, 90%);
$danger-200: mix(white, $danger-500, 70%);
$danger-300: mix(white, $danger-500, 50%);
$danger-400: mix(white, $danger-500, 30%);
$danger-600: mix(black, $danger-500, 30%);
$danger-700: mix(black, $danger-500, 50%);
$danger-800: mix(black, $danger-500, 70%);
$danger-900: mix(black, $danger-500, 80%);

$btn-flat-bg: rgba(0, 0, 0, 0.02);
$btn-flat-border: rgba(0, 0, 0, 0.02);

$navbar-height: 50px;
$main-nav-width: 250px;

$chatbot-inputarea-height: 72px;
$chatbot-sidebar-height-down-lg: 66px;
$chatbot-conversation-bg: linear-gradient($gray-200 0%, $gray-100 100%), $gray-100;

$card-padding-y: 0.625rem;
$card-padding-x: 1rem;
$card-padding: $card-padding-x;
$card-padding-sm: $card-padding-y $card-padding-x;
$card-border-top: solid 4px $primary;
$card-border-divider: solid 1px $gray-200;