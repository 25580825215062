/* Rrose layout rewritten */

.leaflet-rrose {
  position: absolute;
  text-align: center;
}

.leaflet-rrose-content-wrapper {
  padding: 1px;
  text-align: left;
  overflow: auto;
}

.leaflet-rrose-content {
  margin: 14px 20px;
  overflow-wrap: anywhere;
}


a.leaflet-rrose-close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 5px 0 0;
  text-align: center;
  width: 18px;
  height: 14px;
  font: 16px/14px Tahoma, Verdana, sans-serif;
  color: #c3c3c3;
  text-decoration: none;
  font-weight: bold;
}

a.leaflet-rrose-close-button:hover {
  color: #999;
}

.leaflet-rrose-content p {
  margin: 18px 0;
}

.leaflet-rrose-scrolled {
  overflow: auto;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

/* Visual appearance */

.leaflet-rrose-content-wrapper {
  background: white;

  box-shadow: 0 3px 10px #888;
  -moz-box-shadow: 0 3px 10px #888;
  -webkit-box-shadow: 0 3px 14px #999;
}

.leaflet-rrose-content-wrapper {
  -moz-border-radius:    20px;
  -webkit-border-radius: 20px;
  border-radius:         20px;
}

.leaflet-rrose-content {
  font: 12px/1.4 "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.leaflet-rrose-dot {
  border: 4px solid white;
  translate: -4px -4px;
  border-radius: 50%;
  position: absolute;
  z-index: 2;
  animation: fadeIn 0.2s linear both;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}