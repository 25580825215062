@keyframes edited {
  0% {
    background-color: #A6E2A9;
  }

  100% {
    background-color: transparent;
  }
}

@keyframes edited-error {
  0% {
    background-color: #E8AAA4;
  }

  100% {
    background-color: transparent;
  }
}

.datagrid {
  background-color: #fff;
  padding: 1em;
  box-sizing: border-box;
}

.datagrid .datagrid-collapse-filters-button-row {
  margin-bottom: 0.5em;
}

.datagrid .col-action .dropdown {
  display: inline-block;
}

.datagrid .datagrid-row-inline-add.datagrid-row-inline-add-hidden {
  display: none;
}

.datagrid .datagrid-row-columns-summary td {
  border-top: 2px solid #bbb;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  font-weight: bold;
}

.datagrid .datagrid-row-columns-summary td:first-child {
  border-left: 1px solid #ddd;
}

.datagrid .datagrid-row-columns-summary td:last-child {
  border-right: 1px solid #ddd;
}

.datagrid .datagrid-toolbar {
  margin-top: .35em;
  float: right;
  display: inline-block;
}

.datagrid .datagrid-toolbar > span {
  margin-left: 1em;
}

.datagrid .datagrid-toolbar > span > a {
  margin-left: 0.5em;
}

.datagrid .datagrid-exports .btn {
  margin-left: 0.5em;
}

.datagrid .datagrid-exports .btn:first-child {
  margin-left: 0;
}

.datagrid .datagrid-settings {
  display: inline-block;
}

.datagrid .datagrid-settings .dropdown-menu--grid {
  font-size: 12px;
}

.datagrid .datagrid-settings .dropdown-menu--grid li .fa {
  margin-right: 0.5em;
}

.datagrid .row-reset-filter {
  text-align: right;
  margin-bottom: 0.5em;
}

.datagrid .row-filters > div {
  margin-bottom: 0.5em;
}

.datagrid .datagrid-manual-submit {
  margin-bottom: 0.5em;
}

.datagrid .filter-range-delimiter {
  text-align: center;
}

.datagrid .bootstrap-select.input-sm > .btn {
  padding: 5px 25px 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.datagrid table {
  margin: 0;
}

.datagrid table thead tr th .group-actions {
  display: none;
}

.datagrid table thead tr .bootstrap-select:not([class*=col-]):not(.input-group-btn) {
  width: auto;
}

.datagrid table thead tr .bootstrap-select:not([class*=col-]):not(.input-group-btn) > .btn {
  width: auto;
}

.datagrid table thead tr .bootstrap-select:not([class*=col-]):not(.input-group-btn) .dropdown-menu li {
  font-size: 13px;
}

.datagrid table thead tr.row-group-actions th {
  border-bottom-width: 0 !important;
  background-color: #f9f9f9;
}

.datagrid table thead tr.row-group-actions .datagrid-selected-rows-count {
  margin-left: 0.3em;
}

.datagrid table thead tr th {
  font-size: 90%;
  vertical-align: top;
}

.datagrid table thead tr th hr {
  margin: 8px -8px;
}

.datagrid table thead tr th .datagrid-column-header-additions {
  float: right;
}

.datagrid table thead tr th .datagrid-column-header-additions a[data-datagrid-reset-filter-by-column] {
  margin-left: 0.3em;
  color: #858585;
}

.datagrid table thead tr th .datagrid-column-header-additions .column-settings-menu {
  opacity: 0;
  cursor: pointer;
  margin-left: 2px;
  display: inline-block;
}

.datagrid table thead tr th .datagrid-column-header-additions .column-settings-menu .dropdown-menu {
  font-size: 12px;
}

.datagrid table thead tr th .datagrid-column-header-additions .column-settings-menu .dropdown-menu li .fa {
  margin-right: 0.5em;
}

.datagrid table thead tr th .datagrid-col-filter-date-range .input-group .datagrid-filter-date-range-last,
.datagrid table thead tr th .datagrid-col-filter-range .input-group .datagrid-filter-date-range-last {
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}

.datagrid table thead tr th .datagrid-col-filter-date-range .input-group .input-group-addon-first,
.datagrid table thead tr th .datagrid-col-filter-range .input-group .input-group-addon-first {
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
  border-left: 0;
}

.datagrid table thead tr th .datagrid-col-filter-date-range .input-group .datagrid-col-filter-datte-range-delimiter,
.datagrid table thead tr th .datagrid-col-filter-range .input-group .datagrid-col-filter-datte-range-delimiter {
  background-color: inherit;
  border: none;
}

.datagrid table thead tr th .datagrid-col-filter-range .form-control {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.datagrid table thead tr th:hover .column-settings-menu {
  opacity: 1;
}

.datagrid table tbody td {
  vertical-align: middle;
}

.datagrid table tbody tr.ui-sortable-helper {
  display: table;
}

.datagrid table tbody tr.row-item-detail {
  display: none;
}

.datagrid table tbody tr.row-item-detail.toggled {
  display: table-row;
}

.datagrid table tbody tr.row-item-detail .item-detail-content {
  display: none;
}

.datagrid table tbody tr.row-item-detail-helper {
  display: none;
}

.datagrid table tbody tr .datagrid-inline-edit .form-control {
  margin: -3px;
  padding-bottom: 4px;
  padding-top: 4px;
  height: 28px;
}

.datagrid table tbody tr td[data-datagrid-editable-url].editing textarea {
  padding: 2px;
  margin: -3px;
}

.datagrid table tbody tr td.edited {
  animation-name: edited;
  animation-duration: 1.2s;
  animation-delay: 0;
}

.datagrid table tbody tr td.edited-error {
  animation-name: edited-error;
  animation-duration: 1.6s;
  animation-delay: 0;
}

.datagrid table th.col-checkbox,
.datagrid table td.col-checkbox {
  padding: 0;
  width: 2.1em;
  text-align: center;
  vertical-align: middle;
}

.datagrid table th.col-checkbox .happy-checkbox,
.datagrid table td.col-checkbox .happy-checkbox {
  margin-right: 0;
}

.datagrid table th.col-checkbox.col-checkbox-first,
.datagrid table td.col-checkbox.col-checkbox-first {
  border-top-color: transparent;
}

.datagrid table th.col-checkbox {
  background-color: #f9f9f9;
}

.datagrid table th.col-action,
.datagrid table td.col-action {
  white-space: nowrap;
  width: 10px;
}

.datagrid table th.col-action {
  text-align: center;
}

.datagrid table td.col-action {
  text-align: right;
}

.datagrid table th.datagrid-fit-content,
.datagrid table td.datagrid-fit-content {
  width: 1%;
  white-space: nowrap;
}

.datagrid .datagrid-tree > .datagrid-tree-header .datagrid-tree-item-right-actions-action {
  opacity: 0;
}

.datagrid .datagrid-tree > .datagrid-tree-item {
  margin-left: 20px;
}

.datagrid .datagrid-tree .datagrid-tree-item {
  position: relative;
}

.datagrid .datagrid-tree .datagrid-tree-item.ui-sortable-placeholder {
  visibility: visible !important;
  background-color: rgba(70, 83, 93, 0.1);
}

.datagrid .datagrid-tree .datagrid-tree-item .datagrid-tree-item-content {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  box-sizing: border-box;
  height: 37px;
  box-shadow: inset 0px -1px 1px -1px #9B9B9B;
}

.datagrid .datagrid-tree .datagrid-tree-item .datagrid-tree-item-content .datagrid-tree-item-left,
.datagrid .datagrid-tree .datagrid-tree-item .datagrid-tree-item-content .datagrid-tree-item-right {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.datagrid .datagrid-tree .datagrid-tree-item .datagrid-tree-item-content .datagrid-tree-item-left {
  order: 1;
}

.datagrid .datagrid-tree .datagrid-tree-item .datagrid-tree-item-content .datagrid-tree-item-left > .chevron {
  -webkit-border-radius: 11px;
  -moz-border-radius: 11px;
  border-radius: 11px;
  width: 22px;
  height: 22px;
  line-height: 20px;
  vertical-align: middle;
  background-color: #fff;
  display: inline-block;
  text-align: center;
  position: relative;
  margin: 0 5px 0 -27px;
  transition: transform 0.2s ease-in-out;
}

.datagrid .datagrid-tree .datagrid-tree-item .datagrid-tree-item-content .datagrid-tree-item-left > .chevron:hover {
  -webkit-box-shadow: 0px 0px 3px 0px #b4b4b4;
  -moz-box-shadow: 0px 0px 3px 0px #b4b4b4;
  box-shadow: 0px 0px 3px 0px #b4b4b4;
}

.datagrid .datagrid-tree .datagrid-tree-item .datagrid-tree-item-content .datagrid-tree-item-left > .chevron.toggle-rotate {
  transform: rotate(90deg);
}

.datagrid .datagrid-tree .datagrid-tree-item .datagrid-tree-item-content .datagrid-tree-item-left > .chevron .fa {
  font-size: 10px;
  transform: translate(1px, 0);
}

.datagrid .datagrid-tree .datagrid-tree-item .datagrid-tree-item-content .datagrid-tree-item-right {
  position: relative;
  order: 2;
  flex-basis: 50%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  flex-direction: row;
}

.datagrid .datagrid-tree .datagrid-tree-item .datagrid-tree-item-content .datagrid-tree-item-right .btn {
  margin-top: -3px;
}

.datagrid .datagrid-tree .datagrid-tree-item .datagrid-tree-item-content .datagrid-tree-item-right .datagrid-tree-item-right-columns {
  white-space: nowrap;
  display: flex;
  flex-basis: 70%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

.datagrid .datagrid-tree .datagrid-tree-item .datagrid-tree-item-content .datagrid-tree-item-right .datagrid-tree-item-right-columns .datagrid-tree-item-right-columns-column {
  padding: 0 7px;
  margin-right: 4px;
  flex-basis: 25%;
}

.datagrid .datagrid-tree .datagrid-tree-item .datagrid-tree-item-content .datagrid-tree-item-right .datagrid-tree-item-right-columns .datagrid-tree-item-right-columns-column:last-child {
  margin-right: 0;
}

.datagrid .datagrid-tree .datagrid-tree-item .datagrid-tree-item-content .datagrid-tree-item-right .datagrid-tree-item-right-actions {
  margin-left: 7px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.datagrid .datagrid-tree .datagrid-tree-item .datagrid-tree-item-content .datagrid-tree-item-right .datagrid-tree-item-right-actions .datagrid-tree-item-right-actions-action {
  margin-right: 4px;
}

.datagrid .datagrid-tree .datagrid-tree-item .datagrid-tree-item-content .datagrid-tree-item-right .datagrid-tree-item-right-actions .datagrid-tree-item-right-actions-action:last-child {
  margin-right: 0;
}

.datagrid .datagrid-tree .datagrid-tree-item .datagrid-tree-item-children:not(.datagrid-tree) {
  margin-left: 28px;
}

.datagrid .datagrid-tree .datagrid-tree-item:not(.has-children) > .datagrid-tree-item-children {
  box-sizing: border-box;
  position: relative;
  width: calc(100% - 28px);
  min-height: 9px;
  margin-top: -9px;
}

.datagrid .datagrid-tree .datagrid-tree-item.has-children > .datagrid-tree-item-children {
  display: none;
}

.datagrid .datagrid-tree .datagrid-tree-item.has-children > .datagrid-tree-item-children .datagrid-tree-item-right {
  flex-basis: calc(50% + 14px);
}

.datagrid .datagrid-tree-item-children .datagrid-tree-item-right {
  flex-basis: calc(50% + 14px);
}

.datagrid .datagrid-tree-item-children .datagrid-tree-item-children .datagrid-tree-item-children .datagrid-tree-item-right {
  flex-basis: calc(50% + 28px) !important;
}

.datagrid .datagrid-tree-item-children .datagrid-tree-item-children .datagrid-tree-item-children .datagrid-tree-item-children .datagrid-tree-item-right {
  flex-basis: calc(50% + 42px) !important;
}

.datagrid .datagrid-tree-item-children .datagrid-tree-item-children .datagrid-tree-item-children .datagrid-tree-item-children .datagrid-tree-item-children .datagrid-tree-item-right {
  flex-basis: calc(50% + 56px) !important;
}

.datagrid .datagrid-tree-item-children .datagrid-tree-item-children .datagrid-tree-item-children .datagrid-tree-item-children .datagrid-tree-item-children .datagrid-tree-item-children .datagrid-tree-item-right {
  flex-basis: calc(50% + 74px) !important;
}

.datagrid .datagrid-tree-item-children .datagrid-tree-item-children .datagrid-tree-item-children .datagrid-tree-item-children .datagrid-tree-item-children .datagrid-tree-item-children .datagrid-tree-item-children .datagrid-tree-item-right {
  flex-basis: calc(50% + 88px) !important;
}

.datagrid .datagrid-tree-item-children .datagrid-tree-item-children .datagrid-tree-item-children .datagrid-tree-item-children .datagrid-tree-item-children .datagrid-tree-item-children .datagrid-tree-item-children .datagrid-tree-item-children .datagrid-tree-item-right {
  flex-basis: calc(50% + 102px) !important;
}

.datagrid .datagrid-tree-item-children .datagrid-tree-item-children .datagrid-tree-item-children .datagrid-tree-item-children .datagrid-tree-item-children .datagrid-tree-item-children .datagrid-tree-item-children .datagrid-tree-item-children .datagrid-tree-item-children .datagrid-tree-item-right {
  flex-basis: calc(50% + 116px) !important;
}

.datagrid .btn {
  transition: all 0.1s ease-in-out;
  white-space: nowrap;
}

.datagrid select {
  text-transform: none;
}

.datagrid .row-grid-bottom {
  font-size: 0;
  padding: 8px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-top: 0;
}

.datagrid .row-grid-bottom .col-items {
  font-size: 14px;
  display: inline-block;
  width: 25%;
}

.datagrid .row-grid-bottom .col-pagination {
  font-size: 14px;
  display: inline-block;
  width: 50%;
}

.datagrid .row-grid-bottom .col-per-page {
  font-size: 14px;
  display: inline-block;
  width: 25%;
}

.datagrid .row-grid-bottom .col-per-page form {
  display: inline-block;
}

.datagrid .row-grid-bottom .col-per-page .form-control {
  width: auto;
  display: inline-block;
}

.datagrid .row-grid-bottom .datagrid-per-page-submit {
  position: absolute;
  visibility: hidden;
  width: 0;
  top: -200px;
}

.datagrid .pagination.active > span {
  color: #fff;
}

.datagrid .pagination > a.disabled {
  color: #989898;
  cursor: not-allowed;
}

.datagrid .pagination > a.active {
  pointer-events: none;
  cursor: default;
}

.datagrid .row-group-actions th {
  font-weight: normal;
}

.datagrid .col-checkbox {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.datagrid .col-checkbox .happy-checkbox {
  margin-top: 2px;
}

.datagrid-column-status-option-icon {
  float: right;
}

.datagrid .col-id {
  white-space: nowrap;
}
