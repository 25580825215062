.selectize-control.plugin-remove_button a.remove-single {
  font-size: 1rem;
  text-decoration: none;
  transition: all .1s ease-out;

  &:hover,
  &:focus { font-weight: 700; }
}
.selectize-input {border-radius: 0;}
.selectize-dropdown-emptyoptionlabel{
  text-align: unset;
}

select.form-control {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23" + str-replace(#{$secondary}, '#', '') + "' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  background-position: right 6px center;
  padding-right: 24px;
  background-repeat: no-repeat;
}

.selectize-control.single .selectize-input:after { right: calc(0.75rem + 3px); }