.card {
  --bs-card-inner-border-radius: 0; // change child's preset border-radius

  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  border: none;
  border-radius: 0;
  background: $card-bg-default;
  box-shadow: $box-shadow-sm;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }

  .card-title {
    width: 100%;
    margin-bottom: 0;
  }

  .card-header {
    min-height: 3rem;
    padding: $card-padding-sm;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border-top: $card-border-top;
    border-bottom: $card-border-divider;

    &.small {
      padding-bottom: 0;
      border-bottom: none;

      .card-title {
        font-size: $font-size-base;
        opacity: 0.5;
      }
    }

    .card-header-control > * { margin-right: 0.25rem; }
    .card-header-control > *:last-child { margin: 0; }

    &.card-danger { border-top: solid 4px $danger; }
  }

  .card-body,
  .card-body.row {
    margin: 0;
    padding: $card-padding-x;

    > .col,
    > [class*=col-] { padding: 0; }
  }

  .card-footer {
    padding: $card-padding-sm;
    border-bottom: $card-border-divider;
  }
}

.card-guide {
  &.bg-gradient { background: $card-guide-bg; }
  &.border-solid { @extend .border-solid; }
  &.warning {
    @extend .border-danger;
    @extend .border-1;
    @extend .border-solid;
  }

  .card-header {
    padding-bottom: 0;
    border: none;
  }
  .card-body { padding: $card-padding-x; }
  .card-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .project-todo {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    list-style: none;
  }
}

.card-diary { border-top: solid 4px $diary-color; }
.card-task { border-top: solid 4px $info; }
.card-finishedTask { border-top: solid 4px $finishedTask-color; }
.card-diary,
.card-task,
.card-finishedTask {
  .card-header { border-top: 0; }
}

.dashboard {
  .progressbar {
    position: relative;

    svg { height: 160px; }

    .progressbar-value {
      max-width: 100px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 1.5rem;
      font-weight: bolder;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .chartjs{
    width: 100% !important;
    max-width: 200px !important;
    min-height: 180px;
    margin: auto;
  }

  .valuebar {
    position: relative;
    .valuebar-value{
      font-size: 1.5rem;
      font-weight: bolder;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 180px;
    }
  }

  div .card {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: center;

    &.card-finishedTask img + span { color: $finishedTask-color; }
    &.card-task img + span { color: $info; }

    .card-image {
      min-height: 180px;
      padding: 1.875rem 0;
      display: flex;
      justify-content: center;
      position: relative;

      img + span {
        min-width: 30px;
        padding: 0 5px;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 15px;
        background: $white;
        font-size: 1.25rem;
        font-weight: 500;
      }
    }
  }
}
