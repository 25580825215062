.user-profile-pointsOverview img { width: 50px; }

.toplist {
  display: flex;
  flex-direction: row;

  > div:not(.popover) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .flex-grow-1 { min-width: 150px; }
  .user-profile-pointsOverview {
    min-width: 200px;

    img { width: 2rem; }
  }
}