// Entrance animations
//
.anim-clip-round-right-bottom-start { animation: anim-clip-round-right-bottom-start 750ms ease-in-out; }
@keyframes anim-clip-round-right-bottom-start {
  0% { clip-path: circle(0 at 93% 105%); }
  100% { clip-path: circle(100% at 50% 50%); }
}
.anim-clip-round-right-bottom-end { animation: anim-clip-round-right-bottom-end 750ms ease-in-out; }
@keyframes anim-clip-round-right-bottom-end {
  0% { clip-path: circle(100% at 50% 50%); }
  100% { clip-path: circle(0 at 93% 105%); }
}

.anim-scale-in-center { animation: anim-scale-in-center 50ms ease-out; }
@keyframes anim-scale-in-center {
  0% {
    transform: scale(0.85);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.anim-jump-25-50 {}
@keyframes anim-jump-25-50 {
  0%  { translate: 0   0;  }
  5%  { translate: 0   3%; }
  30% { translate: 0 -15%; }
  55% { translate: 0   3%; }
  60% { translate: 0   0;  }
}

.anim-fade-in-up { animation: anim-fade-in-up 300ms ease-out both; }
@keyframes anim-fade-in-up {
  0% {
    opacity: 0;
    translate: 0 -80px;
  }
  100% {
    opacity: 1;
    translate: 0 0;
  }
}

// Animation delay
//
.anim-delay-0 { animation-delay: 0ms; }
.anim-delay-50 { animation-delay: 50ms; }
.anim-delay-100 { animation-delay: 100ms; }
.anim-delay-150 { animation-delay: 150ms; }
.anim-delay-200 { animation-delay: 200ms; }
.anim-delay-250 { animation-delay: 250ms; }
.anim-delay-300 { animation-delay: 300ms; }
.anim-delay-350 { animation-delay: 350ms; }
.anim-delay-400 { animation-delay: 400ms; }
.anim-delay-450 { animation-delay: 450ms; }
.anim-delay-500 { animation-delay: 500ms; }
.anim-delay-550 { animation-delay: 550ms; }
.anim-delay-600 { animation-delay: 600ms; }
.anim-delay-650 { animation-delay: 650ms; }
.anim-delay-700 { animation-delay: 700ms; }
.anim-delay-750 { animation-delay: 750ms; }
.anim-delay-800 { animation-delay: 800ms; }
.anim-delay-850 { animation-delay: 850ms; }
.anim-delay-900 { animation-delay: 900ms; }
.anim-delay-950 { animation-delay: 950ms; }
.anim-delay-1000 { animation-delay: 1000ms; }

// Animation duration
//
.anim-duration-0 { animation-duration: 0ms; }
.anim-duration-50 { animation-duration: 50ms; }
.anim-duration-100 { animation-duration: 100ms; }
.anim-duration-150 { animation-duration: 150ms; }
.anim-duration-200 { animation-duration: 200ms; }
.anim-duration-250 { animation-duration: 250ms; }
.anim-duration-300 { animation-duration: 300ms; }
.anim-duration-350 { animation-duration: 350ms; }
.anim-duration-400 { animation-duration: 400ms; }
.anim-duration-450 { animation-duration: 450ms; }
.anim-duration-500 { animation-duration: 500ms; }
.anim-duration-550 { animation-duration: 550ms; }
.anim-duration-600 { animation-duration: 600ms; }
.anim-duration-650 { animation-duration: 650ms; }
.anim-duration-700 { animation-duration: 700ms; }
.anim-duration-750 { animation-duration: 750ms; }
.anim-duration-800 { animation-duration: 800ms; }
.anim-duration-850 { animation-duration: 850ms; }
.anim-duration-900 { animation-duration: 900ms; }
.anim-duration-950 { animation-duration: 950ms; }
.anim-duration-1000 { animation-duration: 1000ms; }

// Animation timing
//
.animation-timing-in { animation-timing-function: ease-in; }
.animation-timing-out { animation-timing-function: ease-out; }
.animation-timing-in-out { animation-timing-function: ease-in-out; }