.create-new-project .card {
  max-width: 600px;
  margin: auto;

  .card-header { display: none; }
}

.form-switch#videocall_platform {

  .form-check-input {
    --bs-form-switch-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
    border-color: #00000033;
  }

  div.d-inline-flex:has(input:not(:checked)) {
    label:first-child {
      font-weight: bold;
      color: $danger;
    }
    .form-check-input { background-color: $danger; }
  }

  div:has(input:checked) {
    label:last-child {
      font-weight: bold;
      color: $blue-500;
    }
    .form-check-input { background-color: $blue-500; }
  }
}

.file-image { max-width: 200px; }

.activated-status { color: $success; }
.concept-status { color: $primary; }
.closed-status { color: $danger; }

.project-status-active .description {
  &.activated { display: block; }
  &.concept { display: none; }
  &.closed { display: none; }
  &.delete { display: none; }
}
.project-status-prepared .description {
  &.activated { display: none; }
  &.concept { display: block; }
  &.closed { display: none; }
  &.delete { display: none; }
}
.project-status-finished .description {
  &.activated { display: none; }
  &.concept { display: none; }
  &.closed { display: block; }
  &.delete { display: none; }
}
.project-status-deleted .description {
  &.activated { display: none; }
  &.concept { display: none; }
  &.closed { display: none; }
  &.delete { display: block; }
}