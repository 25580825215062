.questions .question-box {

  .visibility {
    position: relative;

    input[type="checkbox"] {
      position: absolute;
      opacity: 0;
      z-index: 999;
      cursor: pointer;

      &.client {
        width: 80px;
        height: 20px;
      }

      &.user {
        width: 110px;
        height: 20px;
      }

      &.users {
        width: 160px;
        height: 20px;
      }

      & + label {
        position: relative;
        padding: 0;
        color: $gray-500;
      }

      &:hover + label,
      &:focus + label {
        color: $gray-600;
      }

      &:checked + label {
        color: $primary;
      }

      &:hover:checked + label,
      &:focus:checked + label {
        color: $primary-600;
      }

      & + label::before {
        font-size: 1rem;
        margin-right: 5px;
      }

      &:hover + label.users::before,
      &:focus + label.users::before,
      &:checked + label.users::before {
        margin-right: 5px;
      }
    }
  }

  @media screen and (max-width: 717px) {
    .visibility {
      flex-direction: column;

      input[type="checkbox"] {
        &.client,
        &.user,
        &.users {
          width: 100%;
        }

        + label {
          display: block;
          margin: 1rem;

          &.client {
            margin-top: 0;
          }

          &.users {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}