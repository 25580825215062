.project-list {
  .project-list-item {
    padding: calc(var(--bs-gutter-x) * 0.5);

    .card {
      transition: all 200ms ease-out;

      &.create-new-project {
        min-height: 220px;
      }

      &:hover,
      &:focus {
        box-shadow: $box-shadow-lg;
        transform: scale(1.1);
        z-index: 1;
      }

      .card-img-container {
        height: 220px;
        overflow: hidden;

        .card-img-top {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
    .card-footer {
      height: 0;
      border: none;
    }

    .badge {
      position: absolute;
      top: $card-padding-y;
      right: $card-padding-x;

      &.active { @extend .bg-success; }
      &.prepared { @extend .bg-info; }
      &.finished { @extend .bg-secondary; }
      &.deleted { @extend .bg-danger; }
    }

    .create-new-project {
      background: $primary-100;
      border: dashed 2px $primary-200;

      a {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: $font-size-lg;
        font-weight: 500;
        text-decoration: none;
        color: $primary;
      }

      &:hover,
      &:focus {
        background: $primary-500;
        border-color: transparent;

        a { color: $white; }
      }
    }
  }
}