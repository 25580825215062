.container-wrapper > .main-nav + .container-fluid.private-messages {
  height: calc(100svh - $navbar-height);
  padding: 0;
}
.navbar + .navbar + .container-wrapper > .main-nav + .container-fluid.private-messages { height: calc(100svh - $navbar-height - $navbar-height); }
@include media-breakpoint-up(md) {
  .container-wrapper > .main-nav + .container-fluid.private-messages {
    padding: 0 0 0 $main-nav-width;

    .private-messages-container,
    .private-messages-conversation {
      height: calc(100svh - $navbar-height);
      max-height: calc(100svh - $navbar-height);
    }
  }
  .navbar + .navbar + .container-wrapper > .main-nav + .container-fluid.private-messages {
    .private-messages-container,
    .private-messages-conversation {
      height: calc(100svh - $navbar-height - $navbar-height);
      max-height: calc(100svh - $navbar-height - $navbar-height);
    }
  }
}
@include media-breakpoint-down(md) {
  .container-wrapper > .main-nav + .container-fluid.private-messages .conversation-detail {
    height: calc(100vh - $navbar-height - 65px); // -65px = .private-messages-container height
    z-index: 100; // to overfllow .main-nav
  }
  .navbar + .navbar + .container-wrapper > .main-nav + .container-fluid.private-messages .conversation-detail { height: calc(100svh - $navbar-height - $navbar-height - 65px); }
}

.private-messages {
  .private-messages-list {
    flex-direction: column;
    border-top: $card-border-divider;

    .private-messages-list-item {
      padding: 0.5rem 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      order: 2;
      border-bottom: $card-border-divider;
      text-decoration: none;

      &.active,
      &:hover,
      &:focus { background: $primary-100; }
      &.active { order: 1; }

      .avatar { margin-right: 1rem; }

      .div {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }

  .private-messages-conversation {
    padding: 0;
    display: flex;
    flex-direction: column;
    background-color: $gray-100;

    .private-messages-conversation-detail {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow: auto;

      .private-message {
        min-width: 300px;
        width: 80%;
        max-width: 700px;
        display: flex;
        flex-direction: column;

        .message {
          border: solid 1px $gray-300;
          border-radius: $border-radius;
          background: $gray-200;
        }

        .sender {
          display: flex;
          flex-direction: row;
          align-items: center;

          * { order: 1; }
        }

        .avatar {
          width: 1.5rem;
          height: 1.5rem;
        }

        &.private-message-out {
          margin-left: auto;

          .message {
            border-color: $primary-300;
            background: $primary-100;
          }

          .sender { margin-left: auto; }
          .sender a { order: 2; }
        }
      }
    }
  }
}