.customer-journey-chart {
  .customer-journey-wrapper {
    margin: 20px auto;

    .rating-scales {
      float: left;
      width: 80px;

      .small-rating-scale {
        display: none;
        margin: 0 0 23px 0;
        line-height: 1rem;
        letter-spacing: -0.13rem;

        .small-rating-scale-number {
          margin: 0 5px 0 0;
          font-size: 0.8rem;
        }

        .gold {
          font-size: 1.1rem;
          color: $gold;
        }
      }

      .rating-scale {
        margin-bottom: 24px;
        font-size: 1.1rem;
        line-height: 1rem;
        letter-spacing: -0.13rem;

        .scale-number {
          display: none;
          margin: 0 5px 0 0;
          font-size: 0.8rem;
        }

        .gold {
          color: $gold;
        }
        .single-gold {
          display: none;
          color: $gold;
        }
        .blank {
          color: $gray-200;
        }
      }
      @media screen and (max-width: 63.9375em) {
        .small-rating-scale {
          display: none;
        }
        .rating-scale {
          margin-bottom: 23px;

          .scale-number {
            display: inline-block;
          }

          .gold {
            display: none;
          }
          .single-gold {
            display: inline-block;
          }
          .blank {
            display: none;
          }
        }
      }
    }
    @media screen and (max-width: 63.9375em) {
      .rating-scales {
        display: block;
        width: 40px;
      }
    }

    .chart {
      margin-left: 80px;

      .chart-wrapper {
        position: relative;
        overflow-x: auto;

        .canvas-wrapper {
          height: 240px;
        }
      }
    }
    @media screen and (max-width: 63.9375em) {
      .chart {
        margin-left: 40px;
      }
    }
  }
}